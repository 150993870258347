.loader{
	position: relative;
	width: 150px;
	height: 150px;
	background: white;
	border-radius: 100%;
}

.loader:before {
	position: absolute;
	content: '';
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	border-bottom: 0 solid #ffffff05;
	
	box-shadow: 
		0 -10px 20px 20px #ffffff40 inset,
		0 -5px 15px 10px #ffffff50 inset,
		0 -2px 5px #ffffff80 inset,
		0 -3px 2px #ffffffBB inset,
		0 2px 0px #ff6f7d20,
		0 2px 3px #2d2c7a80,
		0 5px 2px #2d2c7a60,
		0 10px 15px #ff6f7d60,
		0 10px 20px 20px #ffffff40;
	filter: blur(4px);
}
@import '/fonts/Hando-Bold/font-family.css';
@import '/fonts/Hando-Regular/font-family.css';
@import '/fonts/Larken-Regular/font-family.css';

html,
body {
  padding: 0;
  margin: 0;
  height: auto;
}

body, ._next{
  height: auto;
}

#__next >  div{
  display: flex !important;
  flex-flow: column !important;
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.sticky-inner-wrapper{
  width: 100%;
}

.sticky-outer-wrapper{
  width: 100%;
  z-index: 100;
}

.chakra-breadcrumb__list-item {
  text-transform: capitalize;
}

.animate-in{
  animation-name: animateIn;
  animation-duration: 350ms;
  animation-delay: calc(var(--animation-order) * 100ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.animate-rotate {
  animation: 2s rotate linear infinite;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }
  
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
	100% {
		transform: rotate(360deg)
	}
}